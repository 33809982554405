.about-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 710px;
    margin-top: 10px;
    padding: 50px;
    overflow-x: hidden;
    backdrop-filter: blur(20x);
}