@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Open+Sans:wght@300&family=Roboto:wght@300&display=swap');
/*
font-family: 'Inter', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Roboto', sans-serif;
*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    color: #fff;
    z-index: 1;
}

::-webkit-scrollbar {
    width: 0;
}

:root {
    --color-pri: rgb(82, 188, 250);
    --color-pri-light: rgba(82, 188, 250, .445);
    --color-sec: rgb(172, 17, 233);
    --color-sec-light: rgba(172, 17, 233, .274);

    --pc-width: 75%;
    --tab-width: 90%;
    --phone-width: 100%;
    --text-pri: #333;
}

body {
    background: rgb(2, 2, 26);
    min-height: 100vh;
    font-family: 'Inter', sans-serif;
    animation: move 1s linear infinite forwards;
}

body::before {
    content: '';
    position: fixed;
    height: 200px;
    width: 200px;
    background: rgb(0, 255, 255);
    filter: blur(140px);
}
body::after {
    content: '';
    position: fixed;
    top: 200px;
    left: 250px;
    height: 200px;
    width: 200px;
    background: rgb(183, 0, 255);
    filter: blur(140px);
}

html {
    scroll-behavior: smooth;
}

.bg-circle1 {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 200px;
    width: 200px;
    background: rgb(0, 255, 255);
    filter: blur(140px);
}
.bg-circle1 {
    position: fixed;
    top: 100px;
    left: 250px;
    height: 200px;
    width: 200px;
    background: rgb(183, 0, 255);
    filter: blur(150px);
}

.container {
    width: var(--pc-width);
    color: var(--text-pri);
    padding: 10px 40px;
    margin: 5px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 500px) {
    .container {
        width: var(--phone-width);
    }
}
