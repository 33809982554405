.home-container {
    position: relative;
    /*min-height: 100vh;*/
    padding: 50px 40px;
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-container .logo {
    position: inherit;
    margin-top: -50px;
    height: 520px;
    width: 520px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    user-select: none;
}

.home-container .logo.main-img {
    position: absolute;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    cursor: pointer;
    animation: spin 8s linear 1s infinite;
    transform: rotate(360deg);
}

.home-container.logo.main-img {
    animation: spin 8s linear 1s infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.home-container .logo .circle {
    position: absolute;
    height: 100px;
    width: 200px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(4, 4, 44);
    transition: .2s ease;
    opacity: 1;
}

.home-container .logo .main-img .circle {
    background-size: 50%;
}

.home-container .logo .main-img .circle:nth-child(1) {
    background-image: url(../../images/za_mir.png);
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    transform: translate(10%,-220%);
}

.home-container .logo .main-img .circle:nth-child(2) {
    background-image: url(../../images/za_pobedu.png);
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    transform: translate(60%, -115%);
}

.home-container .logo .main-img .circle:nth-child(3) {
    background-image: url(../../images/mi_vmeste.png);
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    transform: translate(-110%, 120%);
}

.home-container .logo .main-img .circle:nth-child(4) {
    background-image: url(../../images/za_rossiyu.png);
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    transform: translate(60%, 5%);
}

.home-container .logo .main-img .circle:nth-child(5) {
    background-image: url(../../images/mne_ne_stidno.png);
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    transform: translate(10%, 120%);
}

.home-container .logo .main-img .circle:nth-child(6) {
    background-image: url(../../images/zov.png);
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    transform: translate(-160%, 5%);
}

.home-container .logo .main-img .circle:nth-child(7) {
    background-image: url(../../images/sila_v_pravde.png);
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    transform: translate(-160%, -115%);
}

.home-container .logo .main-img .circle:nth-child(8) {
    background-image: url(../../images/svoih_ne_brosaem.png);
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    transform: translate(-110%, -220%);
}

.home-container img {
    position: absolute;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    border: 3px solid rgb(108, 161, 197);
    transform: translate(-200%);
    opacity: 0;
    animation: fade-in 1s ease 1 .5s forwards;
}

@keyframes fade-in {
    to {
        transform: translate(0);
        opacity: 1;
    }
}

.home-container h2 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 60%;
    margin-top: 50px;
}

.home-container h2 span {
    font-size: 25px;
    opacity: .8;
    padding-bottom: 5px;
    border-bottom: 2px solid var(--color-pri);
    font-family: 'Open Sans', sans-serif;
}

.home-container h2 p {
    font-size: 20px;
    opacity: 1;
    font-weight: 100;
    margin-top: 1px;
    font-family: 'Open Sans', sans-serif;
}

.scroll-down {
    transform: rotate(90deg);
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.scroll-down hr {
    background-color: blue;
    width: 20px;
}

.scroll-down h5 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 100;
}

.scroll-down .scroll {
    transform: rotate(-90deg);
    font-size: 10px;
}

@media (max-width: 768px) {
    .home-container img {
        height: 200px;
        width: 200px;
    }
    .home-container .logo .main-img .circle {
        background-size: 30%;
    }
    .home-container .logo .circle {
        height: 70px;
        width: 150px;
    }
}

@media (max-width: 420px) {
    .home-container img {
        height: 150px;
        width: 150px;
    }
    .home-container .logo .main-img .circle {
        background-size: 40%;
    }
    .home-container .logo .circle {
        height: 50px;
        width: 100px;
    }
}

@media (max-width: 720px) {
    .home-container h2 p {
        font-size: 14px;
    }
}
