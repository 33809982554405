.navigation {
    position: fixed;
    left: 40px;
    top: 50%;
    transform: translate(-100px, -50%);
    background-color: rgba(0, 0, 0, .075);
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 23px;
    border-radius: 50%;
    opacity: 0;
    animation: fade-nav-in 1s ease 1 1s forwards;
}

@keyframes fade-nav-in {
    to {
        transform: translate(0, -50%);
        opacity: 1;
    }
}

.navigation a {
    position: relative;
    background-color: rgba(0, 0, 0, .377);
    border-radius: 50%;
}

.icon {
    font-size: 50px;
    border-radius: 50%;
    padding: 10px;
    transition: fill .1s ease;
    fill: rgb(209, 217, 252);
}

.navigation .icon.active-nav {
    fill: rgb(88, 117, 248);
    border: 2px solid rgb(88, 117, 248);
}

.navigation .icon:hover {
    fill: rgba(88, 117, 248, .151);
    border: 2px solid rgba(88, 117, 248, .192);
}

.navigation .icon .active-nav:hover {
    fill: rgb(88, 117, 248);
    border: 2px solid rgb(88, 117, 248);
}

@media (max-width: 420px) {
    .navigation {
        display: none;
    }
}
