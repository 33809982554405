.qualification-text {
    font-size: 20px;
    top: 10px;
}

.qualifications-container {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 85%;
    width: 90%;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 15px;
    /*user-select: none;*/
}

.qualifications-container .qualification {
    position: relative;
    height: 580px;
    min-width: 40%;
    background-color: rgb(9, 6, 31);
    border-radius: 20px;
    margin: 0 20px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.qualifications-container .qualification .content {
    height: 85%;
    width: 90%;
    border-radius: 15px;
    margin-top: 20px;
    background-size: contain;
    background-position: center;
}

.qualifications-container .qualification h1 {
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 20px;
}

/*.qualifications-container .qualification .content.html {*/
/*    background-image: url(../../images/mi_vmeste.png);*/
/*    background-position: center;*/
/*    background-size: 60px;*/
/*}*/

/*.qualifications-container .qualification .content.css {*/
/*    background-image: url(../../images/mne_ne_stidno.png);*/
/*    background-position: center;*/
/*    background-size: 60px;*/
/*}*/

/*.qualifications-container .qualification .content.javascript {*/
/*    background-image: url(../../images/sila_v_pravde.png);*/
/*    background-position: center;*/
/*    background-size: 60px;*/
/*}*/

/*.qualifications-container .qualification .content.bootstrap {*/
/*    background-image: url(../../images/svoih_ne_brosaem.png);*/
/*    background-position: center;*/
/*    background-size: 60px;*/
/*}*/

/*.qualifications-container .qualification .content.c-plus-plus {*/
/*    background-image: url(../../images/za_mir.png);*/
/*    background-position: center;*/
/*    background-size: 60px;*/
/*}*/

/*.qualifications-container .qualification .content.c-sharp {*/
/*    background-image: url(../../images/za_pobedu.png);*/
/*    background-position: center;*/
/*    background-size: 60px;*/
/*}*/

/*.qualifications-container .qualification .content.kotlin {*/
/*    background-image: url(../../images/za_rossiyu.png);*/
/*    background-position: center;*/
/*    background-size: 60px;*/
/*}*/
/*.qualifications-container .qualification .content.react {*/
/*    background-image: url(../../images/za_rossiyu.png);*/
/*    background-position: center;*/
/*    background-size: 60px;*/
/*}*/

@media (max-width: 720px) {
    .qualifications-container .qualification {
        min-width: 80%;
    }
    .qualifications-container .qualification .content {
        font-size: 14px;
    }
}
