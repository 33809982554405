.header-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 5px;
    transform: translateY(-100%);
    opacity: 0;
    /*backdrop-filter: blur(20px);*/
    animation: fade-in 1s ease 1 1s forwards;
}

@keyframes fade-in {
    0%{
        transform: translateY(0);
        opacity: 1;
    }
}

.header-container-title {
    display: flex;
    flex-direction: column;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 2px;
    font-family: 'Roboto', sans-serif;
}

.header-container-title p {
    font-size: 15px;
}

.header-container h2 {
    font-size: 16px;
    font-weight: 100;
    color: var(--color-pri);
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 1px;
}

.header-container-logo {
    width: 80px;
    height: auto;
}